import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { reset } from "../features/enrollee/enrolleeSlice";
import { toast } from "react-toastify";
import axios from "axios";
import QuickTutorialCard from "../components/enrollee/QuickTutorialCard";
import ProfileUploader from "../components/enrollee/ProfileUploader";
import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import LinkModal from "../components/enrollee/LinkModal";
import COPPAComplianceModal from "../components/enrollee/COPPAComplianceModal";

const Enrollee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [coppaModalOpen, setCoppaModalOpen] = useState(false);
  const mode = location.state?.enrollee ? 'edit' : 'add';

  useEffect(() => {
    if (!user?.onboarded) {
      setCoppaModalOpen(true);
    }
  }, [user]);

  const initialFormData = location.state?.enrollee ? {
    _id: location.state.enrollee._id,
    profileImg: location.state?.enrollee.profileImg,
    firstName: location.state?.enrollee.firstName,
    lastName: location.state?.enrollee.lastName,
    allergyName: location.state?.enrollee.allergyName,
    allergyDescription: location.state?.enrollee.allergyDescription,
    contactNumbers: location.state?.enrollee.contactNumbers,
    generatedLink: location.state?.enrollee.link,
  } : {
    profileImg: "",
    firstName: "",
    lastName: "",
    allergyName: "",
    allergyDescription: "",
    contactNumbers: [""],
    generatedLink: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContactChange = (index, value) => {
    const newContactNumbers = [...formData.contactNumbers];
    newContactNumbers[index] = value;
    setFormData({
      ...formData,
      contactNumbers: newContactNumbers,
    });
  };

  const addContactNumber = () => {
    setFormData({
      ...formData,
      contactNumbers: [...formData.contactNumbers, ""],
    });
  };

  const deleteContactNumber = (index) => {
    setFormData({
      ...formData,
      contactNumbers: formData.contactNumbers.filter((_, i) => i !== index),
    });
  };

  const generateRandomString = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleGenerateLink = () => {
    if(mode === 'add') {
      const randomString = generateRandomString();
      setFormData({
        ...formData,
        generatedLink: randomString,
      });
    }
    setLinkModalOpen(!linkModalOpen);
  };

  const [profileImgURL, setProfileImgURL] = useState(
    initialFormData.profileImg
  );

  const handleProfileImgChange = (event) => {
    const selectedFile = event.target.files[0];
    setFormData({
      ...formData,
      profileImg: selectedFile,
    });
    if (selectedFile) {
      const objectURL = URL.createObjectURL(selectedFile);
      setProfileImgURL(objectURL);
    }
  };

  const handleAddNewEnrollee = async (addAnotherUser = false) => {
    setIsLoading(true);
    let newFormData = new FormData();

    newFormData.append("addedBy", user?._id);
    newFormData.append("firstName", formData.firstName);
    newFormData.append("lastName", formData.lastName);
    newFormData.append("allergyName", formData.allergyName);
    newFormData.append("allergyDescription", formData.allergyDescription);
    newFormData.append(
      "contactNumbers",
      JSON.stringify(formData.contactNumbers)
    );
    newFormData.append("generatedLink", formData.generatedLink);
    newFormData.append("profileImg", formData.profileImg);

    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const url = formData._id
      ? `${process.env.REACT_APP_API_URL}/enrollee/update/${formData._id}`
      : `${process.env.REACT_APP_API_URL}/enrollee/add`;

    const method = formData._id ? "put" : "post";

    try {
      const response = await axios[method](url, newFormData, config);
      if (response.status === 201 || response.status === 200) {
        toast.success(formData._id ? "Enrollee updated!" : "Enrollee added!");
        setLinkModalOpen(!linkModalOpen);
        if(!addAnotherUser) {
          navigate("/");
        } else {
          setFormData({
            profileImg: "",
            firstName: "",
            lastName: "",
            allergyName: "",
            allergyDescription: "",
            contactNumbers: [""],
            generatedLink: "",
          });
        }

        dispatch(reset());
      }
    } catch (error) {
      console.log(error.response)
      toast.error(
        error.response?.data?.error ||
          "An error occurred. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="relative flex flex-col md:flex-row justify-between gap-6 md:gap-10 h-screen p-5 md:p-8">
        {(linkModalOpen || coppaModalOpen) && (
          <div className="fixed inset-0 bg-black opacity-50"></div>
        )}
        <div className="w-full md:w-[35%]">
          <QuickTutorialCard />
        </div>
        <div className="w-full md:w-[65%] flex flex-col gap-5">
          <div className="flex flex-col gap-3 md:gap-2 2xl:gap-4">
            <div className="flex flex-col gap-1 md:gap-2 2xl:gap-4">
              <h1 className="font-manropeBold md:text-[16px] xl:text-xl 2xl:text-2xl">
                Enrollee Information
              </h1>
              <ProfileUploader
                profileImg={profileImgURL}
                handleProfileImgChange={handleProfileImgChange}
              />
            </div>

            <div className="flex flex-col md:flex-row items-center gap-2 md:gap-4 pb-4 border-b-2 border-stroke">
              <div className="w-full flex flex-col gap-2 md:gap-4">
                <InputField
                  label="First Name"
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <p className="text-primary font-urbanistMedium text-[14px] md:text-[12px] xl:text-lg">
                  *Please do not include the names of children under 18.
                </p>
              </div>

              <div className="w-full flex flex-col gap-2 md:gap-4">
                <InputField
                  label="Last Name"
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <p className="text-primary font-urbanistMedium text-[14px] md:text-[12px] xl:text-lg">
                  *Please do not include the names of children under 18.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 md:gap-2 2xl:gap-4">
            <div className="flex flex-col gap-1">
              <h1 className="font-manropeBold md:text-[16px] xl:text-xl 2xl:text-2xl">
                Allergy Details
              </h1>
              <p className="font-manropeMedium text-bodyText text-[14px] md:text-[12px] xl:text-lg">
                Here you can add your allergy details.
              </p>
            </div>

            <div className="flex flex-col md:flex-row items-start gap-2 md:gap-4 pb-4 border-b-2 border-stroke">
              <div className="w-full flex flex-col gap-2 md:gap-4">
                <InputField
                  label="Allergy Name"
                  type="text"
                  placeholder="lorem ipsum dolor"
                  name="allergyName"
                  value={formData.allergyName}
                  onChange={handleChange}
                />
                <p className="text-primary font-urbanistMedium text-[14px] md:text-[12px] xl:text-lg">
                  *Do not include sensitive information such as address
                </p>
              </div>

              <div className="w-full flex flex-col gap-4">
                <InputField
                  label="Description"
                  type="text"
                  placeholder="Lorem ipsum dolor ist amet ..."
                  name="allergyDescription"
                  value={formData.allergyDescription}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 md:gap-2 2xl:gap-4">
            <div className="flex flex-col gap-1">
              <h1 className="font-manropeBold md:text-[16px] xl:text-xl 2xl:text-2xl">
                Contact Numbers
              </h1>
              <p className="font-manropeMedium text-bodyText text-[14px] md:text-[12px] xl:text-lg">
                You can add up to 4 contact numbers which you can share by using
                a generated link.
              </p>
            </div>

            {formData.contactNumbers.map((contact, index) => (
                <div
                    className="flex flex-col md:flex-row md:items-center gap-2 md:gap-4"
                    key={index}
                >
                  <InputField
                      label="Contact Number"
                      type="text"
                      placeholder="Enter your contact number..."
                      value={contact}
                      onChange={(e) => handleContactChange(index, e.target.value)}
                      hasDeleteButton={true}
                      onDeleteClick={() => deleteContactNumber(index)}
                  />
                </div>
            ))}

            <Button
                variant="outline"
              type="button"
              className="w-full md:w-[25%] mb-1 lg:mb-0 ms-auto"
              onClick={addContactNumber}
            >
              <p className="md:text-[8px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px]">
                + Add new contact number
              </p>
            </Button>
          </div>

          <div className="w-full flex flex-col gap-3">
            <div>
              <Button
                variant="filled"
                className="md:w-[30%] lg:w-[20%]"
                onClick={handleGenerateLink}
              >
                {mode === 'add' ? 'Generate Link' : 'Save'}
              </Button>
            </div>
            <p className="font-manropeMedium text-bodyText text-[14px] md:text-[12px] xl:text-lg pb-10">
              Contact information submitted will be made available using the
              provided link. We will not actively share your information with
              other parties.
            </p>
          </div>
        </div>
      </div>
      {linkModalOpen && (
        <LinkModal
          name={`${formData.firstName} ${formData.lastName}`}
          isLoading={isLoading}
          generatedLink={formData.generatedLink}
          onAddAnotherUserClick={() => handleAddNewEnrollee(true)}
          onContinueClick={() => handleAddNewEnrollee(false)}
          closeModal={() => setLinkModalOpen(false)}
        />
      )}
      {coppaModalOpen && (
        <COPPAComplianceModal
          onContinueClick={() => setCoppaModalOpen(false)}
        />
      )}
    </>
  );
};

export default Enrollee;
