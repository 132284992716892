import axios from "axios";
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

export default function EnrolleDetails() {
  const [enrolleData, setEnrolleData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchEnrolleDetails = async () => {
    try {
      setIsLoading(true)
      const API_URL = `${process.env.REACT_APP_API_URL}/enrollee/${id}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.get(`${API_URL}`, config);

      if (response.status === 200) {
        // console.log(response.data)
        setEnrolleData(response.data)
      } else {
        navigate('/')
      }

    } catch (error) {
      console.log(error.message);
      navigate("/")
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      fetchEnrolleDetails();
    } else {
      navigate("/")
    }
    // console.log(id)d
  }, [])
  return (
    <>
      <div className='max-w-screen-xl mx-auto'>
        <div className='px-5 sm:px-10 md:px-20'>
          <div className='py-4 border-b border-primary/50'>
            <Link to={"/"}>
              <img src='/logo.png' className='h-16' />
            </Link>
          </div>
          <div>
            <div className=" bg-blue-50 w-full py-12">
              <div className="max-w-md mx-auto bg-primary/20 rounded-xl overflow-hidden w-full shadow-xl shadow-primary">
                <div className="border-b px-4 ">
                  <div className="text-center my-4">
                    <img className="h-32 w-32 rounded-full border-4 border-primary mx-auto my-4"
                      src={enrolleData?.profileImg || "https://randomuser.me/api/portraits/women/21.jpg"} alt="user" />
                    <div className="py-2">
                      <h3 className="font-bold text-4xl">{enrolleData?.firstName + " " + enrolleData?.lastName || "Cait Genevieve"}</h3>
                    </div>
                    <div className="py-2">
                      <h3 className="font-medium text-2xl text-gray-500 mb-4">In case of an emergency, please contact my guardian for assistance.</h3>
                    </div>
                    <div className="pb-4">
                      <h2>Parent Name</h2>
                      <h3 className="font-semibold text-xl text-gray-700 mb-1 capitalize">{enrolleData?.addedBy?.fullName}</h3>
                    </div>
                    <div className="pb-4">
                      <h2>Allergy Name</h2>
                      <h3 className="font-semibold text-xl text-gray-700 mb-1 capitalize">{enrolleData?.allergyName}</h3>
                    </div>
                    <div className="pb-4">
                      <h2>Allergy Description</h2>
                      <h3 className="font-semibold text-xl text-gray-700 mb-1 capitalize">{enrolleData?.allergyDescription}</h3>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-4">
                  <div className="text-center space-y-3">
                    <h3 className="font-bold text-2xl text-gray-900">Parents contact Numbers</h3>
                    <div className="space-y-1.5">
                      {
                        enrolleData && enrolleData?.contactNumbers.map((number, index) => (
                          <p key={index} className="text-pretty">{number}</p>
                        ))
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
