import React from "react";

const Avatar = ({ imgUrl }) => {
  return (
      <>
          {
              imgUrl ?
                  <img src={ `${imgUrl}`} className="rounded-full h-[35px] w-[35px]" alt="user-avatar" /> :
                  <></>
          }
      </>
  );
};

export default Avatar;
