import React from "react";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import HelpItemCard from "../components/helpAndSupport/HelpItemCard";

const HelpAndSupport = () => {

  return (
    <div>
      <Sidebar>
        <Header headerFor={"help"} name={"Help & Support"} />
        <div className="p-3 md:p-5 2xl:p-14 flex flex-col gap-10">
          <div className="space-y-2 text-center">
            <h1 className="font-urbanistBold md:text-[18px] lg:text-[22px] xl:text-[26px] 2xl:text-3xl">Help & Support</h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              For any additional support, please contact us at: <a href="mailto:support@thereunite.com" className="text-primary">support@thereunite.com</a>
            </p>
          </div>
          <div className='flex items-center justify-center'>
            <video src='/assets/videos/guide.mp4' autoPlay muted loop playsInline className='h-[150px] w-[300px] md:h-[300px] md:w-[600px] object-cover'/>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default HelpAndSupport;
