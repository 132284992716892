import React, { useEffect, useState } from "react";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import { IoSettingsOutline } from "react-icons/io5";
import { TbLogout2 } from "react-icons/tb";
import InputField from "../components/common/InputField";
import Button from "../components/common/Button";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { editProfile, logout, reset } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import ProfileUploader from "../components/enrollee/ProfileUploader";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    fullName: user?.fullName || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    currentPassword: "",
    newPassword: "",
    profileImage: user?.profileImage || "",
    file: undefined,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    for(let key of Object.keys(formData)) {
      data.append(key, formData[key]);
    }
    dispatch(editProfile(data));
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }

    if (isSuccess) {
      toast.success("Profile updated successfully!");
      dispatch(reset());
    }
  }, [dispatch, isSuccess, isLoading, isError])

  const handleDiscard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFormData({
      fullName: user?.fullName || "",
      email: user?.email || "",
      phoneNumber: user?.phoneNumber || "",
      currentPassword: "",
      newPassword: "",
      profileImage: user?.profileImage || "",
      file: undefined,
    })
  }

  const onFileSelect = (e) => {
    const files = e.currentTarget.files;
    if(files.length > 0) {
      const file = files[0];
      setFormData({...formData, profileImage: URL.createObjectURL(file), file: file });
    }
  }

  return (
    <div>
      <Sidebar>
        <Header headerFor={"profile"} name={"Guardian Profile"} />
        <div className="p-5 flex flex-col md:flex-row items-start gap-4">
          <div className="w-[100%] md:w-[30%] border border-stroke rounded-xl">
            <h1 className="font-urbanistSemibold p-4 md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              Account Settings
            </h1>
            <div>
              <div className="p-3 md:p-3 2xl:p-5 border-b border-t flex items-center md:gap-1.5 2xl:gap-3 bg-[#53C2E312] cursor-pointer">
                <div className="text-[18px] md:text-[25px] mr-1 md:mr-0">
                  <IoSettingsOutline className="text-primary" />
                </div>
                <p className="font-urbanistMedium text-primary text-[16px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                  Account Information
                </p>
              </div>
              <div
                className="p-3 md:p-3 2xl:p-5 flex items-center md:gap-1.5 2xl:gap-3 cursor-pointer"
                onClick={handleLogout}
              >
                <div className="text-[18px] md:text-[25px] mr-1 md:mr-0">
                  <TbLogout2 />
                </div>
                <p className="font-urbanistMedium text-[16px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                  Logout
                </p>
              </div>
            </div>
          </div>

          <div className="w-[100%] md:w-[70%] border border-stroke rounded-xl">
            <h1 className="font-urbanistSemibold p-4 md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              Account Information
            </h1>
            <form
                onSubmit={handleSubmit}
                className="p-4 border-t flex flex-col gap-6"
            >
              <div className="flex flex-col md:flex-row gap-8">
                <ProfileUploader isUserProfile={true} profileImg={formData.profileImage} handleProfileImgChange={onFileSelect} />
              </div>


              <div className="flex flex-col md:flex-row gap-8">
                <InputField
                    label="Full Name"
                    type="text"
                    name="fullName"
                    placeholder={"Enter your full name"}
                    value={formData.fullName}
                    onChange={handleChange}
                />
              </div>

              <div className="flex flex-col md:flex-row gap-8">
                <InputField
                    label="Email Address"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                />

                <InputField
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                />
              </div>

              <div className="flex flex-col md:flex-row gap-8">
                <InputField
                    label="Current Password"
                    type="password"
                    name="currentPassword"
                    placeholder={"*******"}
                    value={formData.currentPassword}
                    onChange={handleChange}
                />

                <InputField
                    label="New Password"
                    type="password"
                    name="newPassword"
                    placeholder="Enter your new password"
                    value={formData.newPassword}
                    onChange={handleChange}
                />
              </div>

              <div className="flex gap-3 md:gap-3 2xl:gap-6 my-4">
                <Button
                    variant="filled"
                    type="submit"
                    className="text-[14px] md:text-[16px] md:w-[30%] 2xl:w-[20%] font-urbanistSemibold rounded-md flex justify-center items-center"
                >
                  {isLoading ? <div class="loader"></div> : "Save Changes"}
                </Button>
                <Button
                    variant="outline"
                    onClick={handleDiscard}
                    className="text-[14px] md:text-[16px] md:w-[25%] 2xl:w-[15%] font-urbanistSemibold rounded-md"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default UserProfile;
