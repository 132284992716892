import React, { useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "../common/Button";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { addSignatures, reset } from "../../features/auth/authSlice";

const COPPAComplianceModal = ({ onContinueClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [sigPad, setSigPad] = useState(null);

  const handleTermsChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  const clearSignature = () => {
    sigPad.clear();
  };

  const saveSignature = () => {
    if (!agreeTerms) {
      toast.error("You must agree to the terms and conditions.");
      return;
    }

    if (sigPad.isEmpty()) {
      toast.error("Please provide a signature.");
    } else {
      const signatureData = sigPad.toDataURL();
      dispatch(addSignatures({ digitalSign: signatureData }));
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error("Failed to save the signatures. Please try again!");
      dispatch(reset());
    }

    if (user?.onboarded && isSuccess) {
      toast.success("Signatures saved.");
      onContinueClick();
      dispatch(reset());
      navigate("/");
    }
  }, [isSuccess, isError, message]);

  return (
    <div className="absolute p-5 inset-0 size-full flex items-start justify-center">
      <div className="bg-white rounded-lg p-7 md:p-6 lg:p-8 xl:p-10 max-w-[90%] md:max-w-[85%] lg:max-w-[60%] xl:max-w-[75%] flex flex-col gap-4 md:gap-4 lg:gap-5 xl:gap-8">
        <div className="text-center space-y-1 md:space-y-3">
          <h1 className="font-urbanistBold md:text-[16px] xl:text-xl 2xl:text-2xl">
            COPPA COMPLIANCE
          </h1>
          <p className="text-[10px] md:text-[16px] text-left font-urbanistMedium leading-tight">
            We are committed to complying with the Children's Online Privacy Protection Act (COPPA) and protecting the privacy of children who use our website. In accordance with COPPA, we do not knowingly collect personal information from children under the age of 18 without verifiable parental consent.<br/>
            Before collecting any personal information from a child under the age of 18, we will make reasonable efforts to obtain verifiable parental consent through digital signature.<br/>
            In the event that we collect personal information from children under the age of 18 with parental consent, the types of information collected may include but are not limited to contact information and allergies and any other information necessary for the provision of our services.<br/>
            Any personal information collected from children under the age of 18 with parental consent will only be used for the purpose for which it was provided, such as administering the NFC patch program and providing associated services. We will not disclose this information to third parties except as permitted by COPPA or with parental consent.<br/>
            Parents or legal guardians have the right to review the personal information collected from their child, request deletion of this information, and refuse to permit further collection or use of their child's information. To exercise these rights, parents or legal guardians can contact us using the contact information provided below. The guardian also has the ability to delete the child’s account entirely. Additionally, once a parent locks the tags, the information on them cannot be updated or changed.<br/>
            We take appropriate technical, physical, and administrative measures to protect the personal information of children under the age of 18 from unauthorized access, disclosure, alteration, or destruction.<br/>
            In the event of any material changes to our COPPA practices, we will provide parents or legal guardians with notice and obtain verifiable parental consent as required by COPPA.<br/>
            If you have any questions or concerns about our COPPA compliance or the collection of personal information from children under the age of 18, please contact us at <strong>support@thereunite.com</strong>
          </p>
        </div>

        <div className="flex flex-col gap-5 md:gap-3 lg:gap-4 xl:gap-7">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreeTerms}
              onChange={handleTermsChange}
              className="w-[22px] h-[22px] cursor-pointer"
            />
            <label
              htmlFor="agreeTerms"
              className="ml-2 font-urbanistMedium text-[14px] md:text-[12px] xl:text-[16px] 2xl:text-lg"
            >
              I agree to all <Link to="/terms-and-conditions" className="text-primary">Terms & Conditions</Link>
            </label>
          </div>

          <div className="flex flex-col gap-3">
            <h1 className="font-urbanistBold md:text-[16px] xl:text-xl 2xl:text-2xl">
              Digital Signature
            </h1>
            <div
              id="signature"
              className="border border-dashed border-primary rounded-xl bg-[#53C2E30D] px-8 py-6 h-[100px] md:h-[100px] lg:h-[120px] xl:h-[140px] 2xl:h-[150px]"
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: "signatureCanvas" }}
                ref={(ref) => setSigPad(ref)}
              />
            </div>
            <button onClick={clearSignature} className="mt-2 text-blue-500">
              Clear
            </button>
          </div>

          <div className="w-full flex flex-col gap-3">
            <Button
              variant="filled"
              onClick={saveSignature}
              className="flex justify-center items-center"
            >
              {isLoading ? <div className="loader"></div> : "Continue"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default COPPAComplianceModal;
