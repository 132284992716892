import React from "react";

const FeaturedContent = () => {
  return (
    <div className="relative">
      <img
        src="/assets/imgs/Allergaurd-Image.jpg"
        className="lg:h-[480px] xl:h-[550px] 2xl:h-[750px]"
        alt="login-page-asset"
      />
    </div>
  );
};

export default FeaturedContent;
