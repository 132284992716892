import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../features/auth/authSlice";
import enrolleeReducer from "../features/enrollee/enrolleeSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    enrollees: enrolleeReducer,
  },
});
