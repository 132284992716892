import axios from "axios";
import {toast} from "react-toastify";

const API_URL = `${process.env.REACT_APP_API_URL}/enrollee`;

// Add enrollee
const addEnrollee = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await axios.post(`${API_URL}/add`, data, config);

  if (response.data) {
    localStorage.setItem("enrollees", JSON.stringify(response.data));
  }

  return response.data;
};

// Get enrollees by userId
const getEnrolleesByUserId = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/all`, config);
  console.log(response)

  if (response.data) {
    localStorage.setItem("enrollees", JSON.stringify(response.data));
  }

  return response.data;
};

// Delete enrollee
const deleteEnrollee = async (token, enrolleeId) => {
  const response = await axios.delete(`${API_URL}/delete/${enrolleeId}`);

  if (response.data) {
    localStorage.setItem("enrollees", JSON.stringify(response.data));
  }

  return response.data;
};

const enrolleeService = {
  addEnrollee,
  getEnrolleesByUserId,
  deleteEnrollee,
};

export default enrolleeService;
