import React from "react";

const ProfileIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0002 3.33337C15.6335 3.33337 12.0835 6.88337 12.0835 11.25C12.0835 15.5334 15.4335 19 19.8002 19.15C19.9335 19.1334 20.0668 19.1334 20.1668 19.15C20.2002 19.15 20.2168 19.15 20.2502 19.15C20.2668 19.15 20.2668 19.15 20.2835 19.15C24.5502 19 27.9002 15.5334 27.9168 11.25C27.9168 6.88337 24.3668 3.33337 20.0002 3.33337Z"
        fill="#53C2E3"
      />
      <path
        d="M28.4666 23.5833C23.8166 20.4833 16.2333 20.4833 11.5499 23.5833C9.43327 25 8.2666 26.9166 8.2666 28.9666C8.2666 31.0166 9.43327 32.9166 11.5333 34.3166C13.8666 35.8833 16.9333 36.6666 19.9999 36.6666C23.0666 36.6666 26.1333 35.8833 28.4666 34.3166C30.5666 32.9 31.7333 31 31.7333 28.9333C31.7166 26.8833 30.5666 24.9833 28.4666 23.5833Z"
        fill="#53C2E3"
      />
    </svg>
  );
};

export default ProfileIcon;
