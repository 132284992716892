import React from "react";

const QuickTutorialCard = () => {
  return (
    <div className="border border-primary rounded-lg p-3 md:p-5 flex flex-col gap-3">
      <h1 className="text-[20px] md:text-lg lg:text-[20px] xl:text-2xl 2xl:text-4xl font-manropeSemibold">Quick Tutorial:</h1>
      <p className="font-manropeMedium text-bodyText text-[14px] md:text-[12px] lg:text-[15px] xl:text-[19px] 2xl:text-xl">
        Welcome to The Reunite tutorial! <br />
        Connect your NFC patch to our platform effortlessly with these simple
        steps: <br /> <br />
        1. Start by completing the form on our website with all the necessary
        details about the patch holder. <br /> <br />
        2. Once you've submitted the form, you'll receive a unique link. Copy
        this link and proceed to your NFC tool app to program your patch with
        the provided link. <br /> <br />
        For a visual walkthrough of these steps, please refer to our tutorial
        video.
      </p>
    </div>
  );
};

export default QuickTutorialCard;
