import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "../svgs/HomeIcon";
import NotificationIcon from "../svgs/NotificationIcon";
import PrivacyIcon from "../svgs/PrivacyIcon";
import HelpIcon from "../svgs/HelpIcon";
import { MdClose } from "react-icons/md";

import { useSelector } from "react-redux";

const Sidebar = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const menus = [
    {
      title: "Dashboard",
      icon: <HomeIcon />,
      path: "/",
    },
    {
      title: "Notifications",
      icon: <NotificationIcon />,
      path: "/notifications",
    },
    {
      title: "Privacy & Security",
      icon: <PrivacyIcon />,
      path: "/privacy-and-security",
    },
    {
      title: "Terms & Conditions",
      icon: <PrivacyIcon />,
      path: "/terms-and-conditions",
    },
    {
      title: "Help & Support",
      icon: <HelpIcon />,
      path: "/help-and-support",
    },
  ];

  useEffect(() => {
    const element = document.getElementById("sidebarToggleBtn");
    if (element) {
      element.addEventListener("click", () => {
        setIsSidebarExpanded(true);
      });
    }
  }, []);

  return (
    <div className="flex">
      <div
        className={`bg-[#00000080] w-[200%] h-[200%] transition-transform duration-300 ease-in-out ${
          isSidebarExpanded ? "translate-x-[0]" : "translate-x-[-100%]"
        } fixed top-0 left-0 z-10`}
      ></div>
      {user?.isLoggedIn && (
        <div
          className={`fixed md:sticky z-[10] bg-white top-0 w-[62%] md:w-[12rem] lg:w-[16.5rem] xl:w-[19.5rem] 2xl:w-[21rem] ${
            isSidebarExpanded ? "translate-x-[0]" : "translate-x-[-100%]"
          } md:translate-x-0 h-screen p-2 md:p-3 lg:p-4 xl:py-5 xl:px-6 flex flex-col gap-5 border-r-2 border-stroke transition-transform duration-300 ease-in-out`}
        >
          <div className="pb-5 border-b-2 border-stroke flex justify-between md:block">
            <img
              src="/assets/imgs/logo.png"
              className="w-[120px] h-[60px] md:w-[70px] md:h-[35px] lg:w-[120px] lg:h-[60px] xl:w-[150px] xl:h-[75px] 2xl:w-[182px] 2xl:h-[90px] object-cover"
              alt="company-logo"
            />
            <div
              className="text-[20px] block md:hidden"
              onClick={() => setIsSidebarExpanded(false)}
            >
              <MdClose />
            </div>
          </div>

          <div className="flex flex-col md:gap-0 lg:gap-2 xl:gap-3 2xl:gap-5">
            <h1 className="text-bodyText font-urbanistMedium md:text-[12px] lg:text-[14px] xl:text-[17px] 2xl:text-xl">
              DASHBOARD
            </h1>
            <div>
              {menus.map((Menu, index) => (
                <Link
                  to={Menu.path}
                  key={index}
                  className={`sidebar-btn h-[1.5rem] lg:h-[2.5rem] xl:h-[3rem] 2xl:h-[3.3rem] p-1 md:p-2 lg:p-3 xl:p-4 flex rounded-md hover:bg-primary group text-black hover:text-white items-center mt-2 md:gap-x-2 lg:gap-x-3 xl:gap-x-[14px] gap-[0.25em] 2xl:gap-x-4 ${
                    window.location.pathname === Menu.path &&
                    "bg-primary text-white active"
                  }`}
                >
                  {Menu.icon}
                  <span className="origin-left duration-200 font-urbanistMedium text-[12px] md:text-[10px] lg:text-[14px] xl:text-[16px] 2xl:text-lg">
                    {Menu.title}
                  </span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="w-full">{children}</div>
    </div>
  );
};

export default Sidebar;
