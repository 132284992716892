import React, { useState, useEffect } from "react";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import NotificationCard from "../components/notifications/NotificationCard";

import axios from "axios";
import { useSelector } from "react-redux";

const Notifications = () => {
  const { user } = useSelector((state) => state.auth);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications/${user._id}`);
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    if (user && user._id) {
      fetchNotifications();
    }
  }, [user]);

  return (
    <div>
      <Sidebar>
        <Header headerFor="notifications" name="Notifications" />
        <div className="p-5 flex flex-col gap-4">
          {notifications.map((notification) => (
            <NotificationCard
              key={notification._id}
              message={notification.message}
              createdAt={notification.createdAt}
            />
          ))}
        </div>
      </Sidebar>
    </div>
  );
};

export default Notifications;
