import React from "react";
import moment from "moment";
import InformationIcon from "../svgs/InformationIcon";

const NotificationCard = ({ message, createdAt }) => {
  const formatDate = (date) => {
    const now = moment();
    const notificationDate = moment(date);

    if (now.isSame(notificationDate, 'day')) {
      return `Today at ${notificationDate.format('h:mm A')}`;
    } else if (now.subtract(1, 'days').isSame(notificationDate, 'day')) {
      return `Yesterday at ${notificationDate.format('h:mm A')}`;
    } else {
      return notificationDate.format('MMMM D [at] h:mm A');
    }
  };

  return (
    <div className="border border-stroke rounded-lg px-6 py-4">
      <div className="flex items-center gap-4">
        <div className="rounded-full p-3 bg-[#53C2E30D]">
          <InformationIcon />
        </div>
        <div>
          <h1 className="font-manropeMedium md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
            {message}
          </h1>
          <p className="text-bodyText font-manropeMedium md:text-[10px] lg:text-[12px] xl:text-[13px] 2xl:text-[14px]">
            {formatDate(createdAt)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
